<template>
  <BaseCardDialog
    v-model="dialog"
    title="False positive / Help Summary"
    :is-loaded="summaryIsLoaded"
    :message="'Summary is loading...'"
  >
    <template v-slot:content>
      <div class="debug-table-div">
        <h2
          class="summary-title"
        >
          General Summary
        </h2>
          <v-data-table
            eager
            :headers="headers"
            :items="getSummary.error_type"
            class="elevation-1 data-table-alert-summary"
            :footer-props="{
              itemsPerPageOptions: [20,50,100, {text: 'All', value: getSummary.error_type.length}]
            }"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.error_number }}</td>
                <td>{{ props.item.count.to_treat }}</td>
                <td>{{ props.item.count.help }}</td>
                <td>{{ props.item.count.false_positive }}</td>
                <td>{{ props.item.count.attributed }}</td>
                <td>{{ props.item.count.non_attributed }}</td>
                <td>{{ props.item.count.fixed }}</td>
                <td>{{ $transformIsoDate(props.item.oldest) }}</td>
              </tr>
            </template>

          </v-data-table>
      </div>

      <div class="debug-table-div">
        <h2
          class="summary-title"
        >
          Users Summary
        </h2>
          <v-data-table
            eager
            :headers="headersUsers"
            :items="getSummary.users_summary"
            class="elevation-1 data-table-alert-summary"
            :footer-props="{
              itemsPerPageOptions: [20,50,100, {text: 'All', value: getSummary.users_summary.length}]
            }"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.help }}</td>
                <td>{{ props.item.false_positive }}</td>
                <td>{{ props.item.attributed }}</td>
                <td>{{ props.item.fixed }}</td>
              </tr>
            </template>
          </v-data-table>
      </div>

    </template>
  </BaseCardDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseCardDialog from '../../Common/BaseCardDialog'

export default {
  name: 'AlertsSummaryDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseCardDialog
  },
  data: function () {
    return {
      dialog: false,
      summaryIsLoaded: false,
      waitBarMessage: 'Summary is loading...',
      headers: [
        { text: 'error type', value: 'error_number' },
        { text: 'to treat', value: 'count.to_treat' },
        { text: 'help', value: 'count.help' },
        { text: 'false positive', value: 'count.false_positive' },
        { text: 'attributed', value: 'count.attribued' },
        { text: 'non attributed', value: 'count.non_attribued' },
        { text: 'fixed', value: 'count.fixed' },
        { text: 'oldest', value: 'oldest' }
      ],
      headersUsers: [
        { text: 'Debugger Name', value: 'name' },
        { text: 'help', value: 'help' },
        { text: 'false positive', value: 'false_positive' },
        { text: 'attributed', value: 'attributed' },
        { text: 'fixed', value: 'fixed' }
      ],
      selectedDebugger: null
    }
  },
  created: function () {

  },
  mounted: function () {
    this.dialog = this.value
  },
  methods: {
    ...mapActions(['getSummaryData']),
    close: function () {
      this.dialog = false
    },
    scrollTo: function (id) {
      let to = document.getElementById(id)
      let body = document.getElementsByClassName('card-body-debugger')[0]
      let pixel = to.offsetTop
      body.scrollTo(0, pixel - 100)
    }
  },
  computed: {
    ...mapGetters(['getSummary', 'getCurrentUser', 'getDebuggersName']),
    currentUserHaveAlertAttributed: function () {
      return this.getSummary.summary_per_user !== undefined &&
        Object.keys(this.getSummary.summary_per_user).indexOf(this.selectedDebugger) !== -1
    }
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    async dialog (val) {
      this.$emit('input', val)
      if (val) {
        this.summaryIsLoaded = true
        await this.getSummaryData()
        if (this.selectedDebugger === null || this.selectedDebugger === undefined) {
          this.selectedDebugger = this.getCurrentUser
        }
        this.summaryIsLoaded = false
      }
    }
  }
}
</script>

<style>
  .card-body-debugger {
    background: #FAFAFA;
  }
  .card-debugger-alert .v-card__text  {
    padding: 2em;
  }

  .card-debugger-alert .group-key-info {
    margin-bottom: 2em;
  }

  .close-button-alert-debugger {
    text-align: right;
  }

  .data-table-alert-summary {
    margin-bottom: 2em;
  }

  .debug-table-info-div {
    padding: 1em;
    color: black;
    background: #f7f7f7;
    font-style: italic;
  }

  .debug-table-info-div span {
    padding: 1em;
  }

  .span-go-to {
    color: #1976d2;
    font-size: 15px;
    cursor: pointer;
  }

  .title-card-debugger-text {
    color: #1976d2;
    font-weight: bold;
  }

  .total-div-user {
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .total-user-debugger {
    color: darkred;
    font-weight: bold;
  }

  h2.summary-title {
    margin-bottom: 1em;
    color: #1976d2;
  }

  .waitbar-debugger-alert .wait-bar-auth {
    margin-top: 0 !important;
  }

  .waitbar-debugger-alert {
    margin-top: 7em;
    margin-bottom: 7em;
  }
</style>
